import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Events/Preprimary/1.jpg';
import p2 from 'assests/Photos/Events/Preprimary/2.jpg';
import p3 from 'assests/Photos/Events/Preprimary/3.jpg';
import p4 from 'assests/Photos/Events/Preprimary/4.jpg';
import p5 from 'assests/Photos/Events/Preprimary/5.jpg';
import p6 from 'assests/Photos/Events/Preprimary/6.jpg';
import p7 from 'assests/Photos/Events/Preprimary/7.jpg';
import p8 from 'assests/Photos/Events/Preprimary/8.jpg';
import p9 from 'assests/Photos/Events/Preprimary/9.jpg';
import p10 from 'assests/Photos/Events/Preprimary/10.jpg';
import p11 from 'assests/Photos/Events/Preprimary/11.jpg';
import p12 from 'assests/Photos/Events/Preprimary/12.jpg';
import CyberClub from 'views/CyberClub';

import SidebarArticles from 'views/Dasara/components/SidebarArticles/SidebarArticles';
import { Typography } from '@mui/material';
import Container from 'components/Container';



const PrePrimary2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
  
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
      },
      {
        src: p10,
        source: p10,
        rows: 1,
        cols: 1,
      },
      {
        src: p11,
        source: p11,
        rows: 1,
        cols: 1,
      },
      {
        src: p12,
        source: p12,
        rows: 1,
        cols: 1,
      },
  

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ANNUAL EXTRAVAGANZA 2022-23
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Classes: PRE-PRIMARY	 Date: 23 FEBRUARY 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The much awaited Pre-Primary Annual Event ‘THE PRE-PRIMARY UNIFIESTA’ was conducted on Thursday, 23 February 2023 for the students of Montessori and Kindergarten at National Public School Yeshwanthpur. It was indeed a special occasion for the students that created an everlasting impression. 
        {/* <br/><br/> */}
        {/* This spectacular annual event engulfed all the exciting and interesting components of celebration for students.
A ceremonial inaugural by the Principals gave a positive start to the festivities that followed. 
The event commenced with the lighting of the lamp by Principal Academics, Ms. Nikitaa Kalro, Principal Administration, Ms. Sheeja Manoj and the guest for the day, eminent resource person, Mrs. Lavanya Prasad. To invoke the blessings of the Almighty, a shloka was chanted by the teachers and students. Teachers and students assembled in the ground to witness the inaugural function. The event was declared open by the Principals and the revelries began. */}


        <br></br>
        The air around was filled with the aura of the annual event. The welcome board consisted of the details of the day’s happenings and the decor was mesmerizing and appealing to the senses of students. The decoration done in every venue was child centric and enchanting. 
        <br></br>
        <strong>Kalakaars – Mask</strong> making activity gave wings to the creativity of students where they made ingenious masks using foam sheets, stickers, sequins and fancy pipe cleaners.
        <br></br>
        <strong>The Fun carpet – Dance Dhamaka</strong> provided an opportunity for students to lose all inhibitions and sway to the music, jump up high and sweat it out to the beat.
        <br></br>
        <strong>Taléscope – The World of Stories,</strong> an endearing story session by a professional story teller, Mrs. Lavanya Prasad was an interactive and engrossing experience for the students. It opened the world of imagination and exposed them to an intricate way of storytelling which enhanced comprehension and moral values.
        <br></br>
        <strong>Swaralaya – Music magicians –</strong> Students were introduced to a range of musical instruments at Swaralaya for them to discover and enjoy. A variety of classical music in many languages were also sung by them in tune and rhythm.
        <br></br>
        <strong>Bon appétit – Eat and Greet </strong>
‘The family that dines together stays together’. Promoting the culture of eating together, students enjoyed their snack time along with their peers and teachers. They conversed about their favourite dish, fruits, vegetables, junk food, nutritional facts and teachers talked about the importance of healthy eating. 
<br></br>

Students were thrilled with the idea of coming to school merrily in colourful clothes carrying only the snack bag, with crazy hairstyles and participating in all the fun activities specially designed for them. The icing on the cake was the take away that each child got which ensured the merriment traversed from school to home.
The annual event concluded with all the grandeur and vibrant elements which undoubtedly was the most exciting, creative and a delightful day for the students and teachers alike
<br></br>
          “Students are the reason we laugh, smile and want to get up every morning.”
It was an enthralling journey for us to present this unique annual event “UNIFIESTA” to make them happy.



        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
DO IT BIG, DO IT RIGHT, AND DO IT WITH STYLE …
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default PrePrimary2023;